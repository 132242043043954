.separator {
    background-image: linear-gradient(
       to right top,
       #3654e7,
       #5c52e9,
       #874feb,
       #914aeb,
       #a745ea,
       #7d68fb,
       #4d7fff,
       #0091ff,
       #00acfd,
       #00bdd9,
       #00c8a3,
       #6bcc70
     );
     height: 6px;
     width: 40%;
     border-radius: 3px;
     margin: 16px 0px;
  }