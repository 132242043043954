.work-card {
    padding: 8px;
    width: 45%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
}
.work-logo {
    height: 80px;
}

.work-info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.work-dates {
    color: black;
    margin-top: 10px;

}

.work-desc {
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: horizontal;
    overflow: hidden ;
    text-overflow: ellipsis;
    max-width: 80%;
    margin-top: 15px;
}



@media only screen and (max-width: 720px) {
    .work-card{
        width: 100%;
    }
}