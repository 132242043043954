html {
    scroll-behavior: smooth;
}
.home{
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: auto;
}

@media only screen and (max-width: 1024px) {
  .home {
    width: 90%;
}
}
@media only screen and (max-width: 512px) {
  .home {
    width: 100%;
  }
}